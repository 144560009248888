<template>
  <div class="relative">
    <img
      :style="{ width: '100%', height: 'auto' }"
      class="inset-0 opacity-100 dark:opacity-0"
      src="/img/hs-stralsund/logo_host_dark.png"
      alt="HOST-Logo Dark"
    />
    <img
      :style="{ width: '100%', height: 'auto' }"
      class="absolute inset-0 opacity-0 dark:opacity-100"
      src="/img/hs-stralsund/logo_host_white.png"
      alt="HOST-Logo White"
    />
  </div>
</template>

<script lang="ts" setup>
import { useInstanceLogoUrl } from "@/composition/apollo/config";

const { instanceLogoUrl } = useInstanceLogoUrl();

withDefaults(
  defineProps<{
    invert?: boolean;
  }>(),
  { invert: false }
);
</script>
