<template>
  <section class="container mx-auto px-2 my-3">
    <h1 class="dark:text-white font-bold">
      {{ config.slogan ?? t("Gather ⋅ Organize ⋅ Mobilize") }}
    </h1>

    <p class="dark:text-white mb-2">{{ config.description }}</p>
    <!-- We don't invite to find other instances yet -->
    <!-- <p v-if="!config.registrationsOpen">
              {{ t("This instance isn't opened to registrations, but you can register on other instances.") }}
          </p>-->
    <div class="flex flex-wrap gap-2 items-center">
      <o-button
        variant="primary"
        tag="router-link"
        :to="{ name: RouteName.LOGIN }"
        >{{ t("Mit Hochschul-Zugangsdaten anmelden") }}</o-button
      >
      <!-- We don't invite to find other instances yet -->
      <!-- <o-button v-else variant="link" tag="a" href="https://joinmastodon.org">{{ t('Find an instance') }}</o-button> -->
      <router-link
        :to="{ name: RouteName.ABOUT }"
        class="o-btn btn o-btn--outlined btn-outlined-"
      >
        {{ t("Learn more about {instance}", { instance: config.name }) }}
      </router-link>
    </div>
  </section>
</template>
<script lang="ts" setup>
import { IConfig } from "@/types/config.model";
import RouteName from "@/router/name";
import { useI18n } from "vue-i18n";

defineProps<{
  config: Pick<
    IConfig,
    "name" | "description" | "slogan" | "registrationsOpen"
  >;
}>();

const { t } = useI18n({ useScope: "global" });
</script>
