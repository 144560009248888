export type CategoryPictureLicencingElement = { name: string; url: string };
export type CategoryPictureLicencing = {
  author: CategoryPictureLicencingElement;
  source: CategoryPictureLicencingElement;
  license?: CategoryPictureLicencingElement;
};

export const categoriesPicturesLicences: Record<
  string,
  CategoryPictureLicencing
> = {
  THEATRE: {
    author: {
      name: "David Joyce",
      url: "https://www.flickr.com/photos/deapeajay/",
    },
    source: {
      name: "Flickr",
      url: "https://www.flickr.com/photos/30815420@N00/2213310171",
    },
    license: {
      name: "CC BY-SA",
      url: "https://creativecommons.org/licenses/by-sa/2.0/",
    },
  },
  SPORTS: {
    author: {
      name: "Md Mahdi",
      url: "https://unsplash.com/@mahdi17",
    },
    source: {
      name: "Unsplash",
      url: "https://unsplash.com/photos/lQpFRPrepQ8",
    },
  },
  MUSIC: {
    author: {
      name: "Michael Starkie",
      url: "https://unsplash.com/@starkie_pics",
    },
    source: {
      name: "Unsplash",
      url: "https://unsplash.com/photos/YjtevpXFHQY",
    },
  },
  ARTS: {
    author: {
      name: "RhondaK Native Florida Folk Artist",
      url: "https://unsplash.com/@rhondak",
    },
    source: {
      name: "Unsplash",
      url: "https://unsplash.com/photos/_Yc7OtfFn-0",
    },
  },
  SPIRITUALITY_RELIGION_BELIEFS: {
    author: {
      name: "The Dancing Rain",
      url: "https://unsplash.com/@thedancingrain",
    },
    source: {
      name: "Unsplash",
      url: "https://unsplash.com/photos/_KPuV9qSSlU",
    },
  },
  MOVEMENTS_POLITICS: {
    author: {
      name: "Kyle Fiori",
      url: "https://unsplash.com/@navy99",
    },
    source: {
      name: "Unsplash",
      url: "https://unsplash.com/photos/moytQ7vzhAM",
    },
  },
  PARTY: {
    author: {
      name: "Marcela Laskoski",
      url: "https://unsplash.com/de/@marcelalaskoski",
    },
    source: {
      name: "Unsplash",
      url: "https://unsplash.com/de/fotos/selektive-fokus-silhouettenfotografie-eines-mannes-der-ein-rot-beleuchtetes-dj-terminal-spielt-YrtFlrLo2DQ",
    },
  },
  FILM_MEDIA: {
    author: {
      name: "Dan Senior",
      url: "https://unsplash.com/@dansenior",
    },
    source: {
      name: "Unsplash",
      url: "https://unsplash.com/photos/ENtn4fH8C3g",
    },
  },
  PHOTOGRAPHY: {
    author: {
      name: "Nathyn Masters",
      url: "https://unsplash.com/@nathynmasters",
    },
    source: {
      name: "Unsplash",
      url: "https://unsplash.com/photos/k3oSs0hWOPo",
    },
  },
  HEALTH: {
    author: {
      name: "Derek Finch",
      url: "https://unsplash.com/@drugwatcher",
    },
    source: {
      name: "Unsplash",
      url: "https://unsplash.com/photos/Gi8Q8IfpxdY",
    },
  },
  GAMES: {
    author: {
      name: "Randy Fath",
      url: "https://unsplash.com/@randyfath",
    },
    source: {
      name: "Unsplash",
      url: "https://unsplash.com/photos/_EoxKxrDL20",
    },
  },
  OUTDOORS_ADVENTURE: {
    author: {
      name: "Davide Sacchet",
      url: "https://unsplash.com/@davide_sak_",
    },
    source: {
      name: "Unsplash",
      url: "https://unsplash.com/photos/RYN-kov1lTY",
    },
  },
  FOOD_DRINKS: {
    author: {
      name: "sina piryae",
      url: "https://unsplash.com/@sinapiryae",
    },
    source: {
      name: "Unsplash",
      url: "https://unsplash.com/photos/bBzjWthTqb8",
    },
  },
  CRAFTS: {
    author: {
      name: "rocknwool",
      url: "https://unsplash.com/@rocknwool",
    },
    source: {
      name: "Unsplash",
      url: "https://unsplash.com/photos/Jcb5O26G08A",
    },
  },
  LGBTQ: {
    author: {
      name: "analuisa gamboa",
      url: "https://unsplash.com/@anigmb",
    },
    source: {
      name: "Unsplash",
      url: "https://unsplash.com/photos/HsraPtCtRCM",
    },
  },
  EDUCATION: {
    author: {
      name: "Element5 Digital",
      url: "https://unsplash.com/de/@element5digital",
    },
    source: {
      name: "Unsplash",
      url: "https://unsplash.com/de/fotos/rote-apfelfrucht-auf-vier-pyle-buchern-OyCl7Y4y0Bk",
    },
  },
  LEISURE_TIME: {
    author: {
      name: "Ben White",
      url: "https://unsplash.com/de/@benwhitephotography",
    },
    source: {
      name: "Unsplash",
      url: "https://unsplash.com/de/fotos/mann-der-tagsuber-am-strand-in-der-nahe-des-sees-ein-buch-liest-1MHU3zpTvro",
    },
  },
  SOCIAL: {
    author: {
      name: "Tim Marshall",
      url: "https://unsplash.com/de/@timmarshall",
    },
    source: {
      name: "Unsplash",
      url: "https://unsplash.com/de/fotos/hande-die-zusammen-mit-roter-herzfarbe-geformt-wurden-cAtzHUz7Z8g",
    },
  },
  CINEMA: {
    author: {
      name: "Jake Hills",
      url: "https://unsplash.com/de/@jakehills",
    },
    source: {
      name: "Unsplash",
      url: "https://unsplash.com/de/fotos/gruppe-von-menschen-die-auf-den-monitor-im-raum-starren-23LET4Hxj_U",
    },
  },
  PRESENTATION: {
    author: {
      name: "Jason Goodman",
      url: "https://unsplash.com/de/@jasongoodman_youxventures",
    },
    source: {
      name: "Unsplash",
      url: "https://unsplash.com/de/fotos/mann-steht-hinter-flachbildschirm-bzqU01v-G54",
    },
  },
  MOBILITY: {
    author: {
      name: "Denys Nevozhai",
      url: "https://unsplash.com/de/@dnevozhai",
    },
    source: {
      name: "Unsplash",
      url: "https://unsplash.com/de/fotos/luftaufnahmen-von-betonstrassen-7nrsVjvALnA",
    },
  },
  CULTURE: {
    author: {
      name: "Debashis RC Biswas",
      url: "https://unsplash.com/de/@rcsalbum",
    },
    source: {
      name: "Unsplash",
      url: "https://unsplash.com/de/fotos/nahaufnahme-einer-person-die-farbige-erde-halt-dyPFnxxUhYk",
    },
  },
  RACING: {
    author: {
      name: "Ralfs Blumbergs",
      url: "https://unsplash.com/de/@rblumbergs",
    },
    source: {
      name: "Unsplash",
      url: "https://unsplash.com/de/fotos/rennsportwagen-in-schwarz-und-grun--EXF9shcTO0",
    },
  },
  RENEWABLE_ENERGIES: {
    author: {
      name: "Appolinary Kalashnikova",
      url: "https://unsplash.com/de/@appolinary_kalashnikova",
    },
    source: {
      name: "Unsplash",
      url: "https://unsplash.com/de/fotos/windkraftanlage-umgeben-von-gras-WYGhTLym344",
    },
  },
  EXCURSION: {
    author: {
      name: "Juan Encalada",
      url: "https://unsplash.com/de/@juanencalada",
    },
    source: {
      name: "Unsplash",
      url: "https://unsplash.com/de/fotos/weisser-und-schwarzer-bus-fahrt-in-der-nahe-des-berges-6mcVaoGNz1w",
    },
  },
  SCIENCE_TECH: {
    author: {
      name: "Alexandre Debiève",
      url: "https://unsplash.com/de/@alexkixa",
    },
    source: {
      name: "Unsplash",
      url: "https://unsplash.com/de/fotos/makrofotografie-einer-schwarzen-leiterplatte-FO7JIlwjOtU",
    },
  },
  NETWORKING: {
    author: {
      name: "Geralt",
      url: "https://pixabay.com/de/users/geralt-9301/",
    },
    source: {
      name: "Pixabay",
      url: "https://pixabay.com/de/photos/spielsteine-netzwerk-vernetzt-1237457/",
    },
  },
};

export const categoriesWithPictures = [
  "EDUCATION",
  "SPORTS",
  "THEATRE",
  "MUSIC",
  "ARTS",
  "MOVEMENTS_POLITICS",
  "SPIRITUALITY_RELIGION_BELIEFS",
  "PARTY",
  "FILM_MEDIA",
  "PHOTOGRAPHY",
  "HEALTH",
  "GAMES",
  "OUTDOORS_ADVENTURE",
  "FOOD_DRINKS",
  "CRAFTS",
  "LGBTQ",
  "LEISURE_TIME",
  "SOCIAL",
  "CINEMA",
  "PRESENTATION",
  "MOBILITY",
  "CULTURE",
  "RACING",
  "RENEWABLE_ENERGIES",
  "EXCURSION",
  "SCIENCE_TECH",
  "NETWORKING"
];
